import React, { useEffect } from 'react';
// import { Helmet } from "react-helmet";
import './style.scss';
import { useNavigate } from 'react-router-dom';

const NotificationSuccess: React.FC = () => {


  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      navigate('/')
    }, 5000)
  });

  return (
    <div className='checkin-page buy-success'>
      <div className="bg-img">
        <img src="/images/checkin/ellipse-bg.png" alt="" />
      </div>
      <div className="content-block mw-850">
        <div className="img-box">
          <img src="/images/checkin/buy-success.gif" alt="" />
        </div>
        <div className="title cl-F9D361">Congratulations!</div>
        <div className="sum cl-fff">you get a free play Voucher!</div>
      </div>
    </div>
  );
}

export default NotificationSuccess;
