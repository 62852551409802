import React, { useEffect, useState } from 'react';
import { Button, Flex, Form, Input, Modal, notification } from 'antd';
import { Danger, TagCross } from 'iconsax-react';
import { mixPostV1, mixPutV1 } from '../../services/mixin';
import { CheckCircleFilled } from '@ant-design/icons';

const { TextArea } = Input

interface Props {
  isShow?: boolean
  content?: string
  message?: string
  onModalClose?: () => void
  onModalSubmit?: () => void
  apiUrl?: any
  apiMethod?: any
  param?: any
  type?: string
  showReason?: boolean
  yesButton?: React.ReactNode
}

const LockModal: React.FC<Props> = (props) => {
  const {
    isShow = false,
    content = 'Are you sure you want to delete this product?',
    message = 'Deleted!',
    type = 'lock',
    onModalClose,
    onModalSubmit,
    apiUrl,
    param,
    showReason = false,
    yesButton,
    apiMethod = 'POST'
  } = props

  const [open, setOpen] = useState(isShow)
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [reason, setReason] = useState('' as any)

  useEffect(() => {
    setOpen(isShow)
    setReason('')
  }, [isShow])

  const onClose = () => {
    setOpen(false);
    if (onModalClose) {
      onModalClose()
    }
  };

  const onSubmit = async () => {
    if (onModalSubmit) {
      setConfirmLoading(true)

      if (apiUrl && param) {
        if (showReason) {
          param.reason_reject = reason
        }
        let res = null
        if (apiMethod === 'POST') {
          res = await mixPostV1(apiUrl, param)
        } else {
          res = await mixPutV1(apiUrl, param)
        }

        if (res.status === 1) {
          setConfirmLoading(false)
          onModalSubmit()
          openNotification('success', message)
        } else {
          setConfirmLoading(false)
          openNotification('error', res.message)
        }
      } else {
        setTimeout(() => {
          setOpen(false);
          setConfirmLoading(false);
          onModalSubmit()
          openNotification('success', message)
        }, 1000);
      }
    } else {
      setConfirmLoading(false)
      onClose()
      openNotification('error', 'Something went wrong')
    }
  };

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'error' = 'success', message = 'Successful manipulation') => {
    api[type]({
      message,
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  const closeIcon = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M3.86312 3.86363C4.03189 3.6949 4.26077 3.60012 4.49942 3.60012C4.73806 3.60012 4.96694 3.6949 5.13572 3.86363L8.99942 7.72733L12.8631 3.86363C12.9461 3.77767 13.0455 3.7091 13.1553 3.66194C13.2651 3.61477 13.3832 3.58994 13.5027 3.5889C13.6222 3.58786 13.7407 3.61063 13.8513 3.65589C13.9619 3.70114 14.0624 3.76797 14.1469 3.85247C14.2314 3.93697 14.2982 4.03746 14.3435 4.14807C14.3887 4.25867 14.4115 4.37719 14.4104 4.49669C14.4094 4.61619 14.3846 4.73429 14.3374 4.84409C14.2902 4.95389 14.2217 5.0532 14.1357 5.13623L10.272 8.99993L14.1357 12.8636C14.2997 13.0334 14.3904 13.2607 14.3883 13.4967C14.3863 13.7327 14.2916 13.9584 14.1248 14.1253C13.9579 14.2921 13.7322 14.3868 13.4962 14.3888C13.2602 14.3909 13.0329 14.3002 12.8631 14.1362L8.99942 10.2725L5.13572 14.1362C4.96598 14.3002 4.73863 14.3909 4.50266 14.3888C4.26668 14.3868 4.04095 14.2921 3.87408 14.1253C3.70721 13.9584 3.61256 13.7327 3.61051 13.4967C3.60846 13.2607 3.69918 13.0334 3.86312 12.8636L7.72682 8.99993L3.86312 5.13623C3.69439 4.96745 3.59961 4.73857 3.59961 4.49993C3.59961 4.26128 3.69439 4.0324 3.86312 3.86363Z" fill="#6B7280" /> </svg>

  const lockIcon = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 15V12.5M13.125 9.375V5C13.125 3.27411 11.7259 1.875 10 1.875C8.27411 1.875 6.875 3.27411 6.875 5V9.375M3.75 16.875V10.625C3.75 9.93464 4.30964 9.375 5 9.375H15C15.6904 9.375 16.25 9.93464 16.25 10.625V16.875C16.25 17.5654 15.6904 18.125 15 18.125H5C4.30964 18.125 3.75 17.5654 3.75 16.875Z" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></svg>

  const unlockIcon = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M10 15V12.5M13.125 5C13.125 3.27411 11.7259 1.875 10 1.875C8.27411 1.875 6.875 3.27411 6.875 5V9.375M3.75 16.875V10.625C3.75 9.93464 4.30964 9.375 5 9.375H15C15.6904 9.375 16.25 9.93464 16.25 10.625V16.875C16.25 17.5654 15.6904 18.125 15 18.125H5C4.30964 18.125 3.75 17.5654 3.75 16.875Z" stroke="#fff" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" /></svg>

  return (
    <>
      {contextHolder}

      <Modal
        centered
        open={open}
        closeIcon={closeIcon}
        footer={null}
        confirmLoading={confirmLoading}
        className='rs-modal w-360 modal-delete'
        rootClassName='rs-modal-root'
        onOk={onSubmit}
        onCancel={onClose}
      >
        <Flex vertical align='center' gap={16} className='text-center'>
          {/* {type === 'lock' ? <Danger size={64} className='color-idle' /> : <Unlock size={64} className='color-idle' />} */}
          <Danger size={64} className='color-idle' />

          <Flex vertical gap={4}>
            <div className="title-20 text-light">Are you sure?</div>
            <div>{content}</div>
          </Flex>

          {
            showReason &&
              <Form layout='vertical' className='rs-form'>
                <Form.Item
                  label="Reason"
                >
                  <TextArea
                    rows={7}
                    value={reason}
                    placeholder='Write reason to Owner know why reject request'
                    className='rs-input'
                    onChange={e => setReason(e.target.value)}
                  />
                </Form.Item>
              </Form>
          }

          <Flex vertical gap={12}>
            {
              showReason
                ?
                  <Button
                    className={`rs-button row-reverse ${ type === 'lock' ? 'danger' : 'success'}`}
                    icon={type === 'lock' ? <TagCross variant='Bulk' size={20} /> : <CheckCircleFilled />}
                    block
                    loading={confirmLoading}
                    onClick={onSubmit}
                  >Yes, {type === 'lock' ? 'reject request' : 'Accept'}</Button>
                :
                  <Button
                    className={`rs-button row-reverse ${ type === 'lock' ? 'danger' : 'success'}`}
                    icon={type === 'lock' ? lockIcon : <CheckCircleFilled />}
                    block
                    loading={confirmLoading}
                    onClick={onSubmit}
                  >Yes, {type === 'lock' ? 'Lock' : 'Accept'}</Button>
            }

            <Button
              block
              className='rs-button'
              onClick={onClose}
            >No</Button>
          </Flex>
        </Flex>
      </Modal>
    </>
  )
}

export default LockModal
