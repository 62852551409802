import React from 'react';
// import { Helmet } from "react-helmet";
import './style.scss';
import { Link } from 'react-router-dom';
import LineChart from './LineChart';
import PieChart1 from './PieChart1';
import PieChart2 from './PieChart2';
import { DatePicker, DatePickerProps, Select } from 'antd';
import { ArrowDownOutlined, ArrowUpOutlined } from '@ant-design/icons';

const CheckInChart: React.FC = () => {


  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    console.log(date, dateString);
  };

  const handleChange = (value: string) => {
    console.log(`selected ${value}`);
  };

  return (
    <div className='checkin-page checkin-chart'>
      <div className="content-block mw-1216">
        <Link to="/" className='btn-back'>Back</Link>
        <div className="title-db-big">
          <img src="/images/checkin/title-db.png" alt="img" />
        </div>
        <i className="title-db-sub">LongZhu Casino</i>
        <div className="list-filter-block">
          <div className="left">
            <div className="list-filter-date">
              <div className="one-item active">Today</div>
              <div className="one-item">Weekly</div>
              <div className="one-item">Monthly</div>
              <div className="one-item">Yearly</div>
            </div>
          </div>
          <div className="center">
            <div className="t-ctrl-select-date-2icon">
              <DatePicker
                onChange={onChange}
              />
              <div className="icon-svg icon-left">
                <img src="/images/checkin/calendar-2.svg" alt="icon" />
              </div>
              <div className="icon-svg icon-right">
                <img src="/images/checkin/arrow-down.svg" alt="icon" />
              </div>
            </div>
            <div className="t-ctrl-select-date-2icon">
              <Select
                defaultValue="shift"
                style={{ width: 132 }}
                onChange={handleChange}
                options={[
                  { value: 'shift', label: 'Shift' },
                  { value: 'lucy', label: 'Lucy' },
                  { value: 'Yiminghe', label: 'yiminghe' },
                ]}
              />
              <div className="icon-svg icon-left">
                <img src="/images/checkin/clock.svg" alt="icon" />
              </div>
              <div className="icon-svg icon-right">
                <img src="/images/checkin/arrow-down.svg" alt="icon" />
              </div>
            </div>
          </div>
          <div className="right">Report created<br></br>by Jackson (12312)</div>
        </div>

        <div className="chart-show">
          <div className="left">
            <div className="list-show">
              <div className="one-item">
                <div className="title">Total Match</div>
                <div className="number">1,210</div>
                <div className="compare">
                  <div className="percent-block">
                    <div className="icon-svg">
                      <ArrowDownOutlined />
                    </div>
                    <div className="percent">10%</div>
                  </div>
                  <div className="text">vs yesterday</div>
                </div>
              </div>

              <div className="one-item">
                <div className="title">Total Payout</div>
                <div className="number">1,210</div>
                <div className="compare">
                  <div className="percent-block">
                    <div className="icon-svg">
                      <ArrowDownOutlined />
                    </div>
                    <div className="percent">10%</div>
                  </div>
                  <div className="text">vs yesterday</div>
                </div>
              </div>

              <div className="one-item">
                <div className="title">Income (USD)</div>
                <div className="number">1,210</div>
                <div className="compare">
                  <div className="percent-block">
                    <div className="icon-svg">
                      <ArrowDownOutlined />
                    </div>
                    <div className="percent">10%</div>
                  </div>
                  <div className="text">vs yesterday</div>
                </div>
              </div>

              <div className="one-item">
                <div className="title">Outcome (USD)</div>
                <div className="number">1,210</div>
                <div className="compare">
                  <div className="percent-block">
                    <div className="icon-svg">
                      <ArrowDownOutlined />
                    </div>
                    <div className="percent">10%</div>
                  </div>
                  <div className="text">vs yesterday</div>
                </div>
              </div>

              <div className="one-item">
                <div className="title">Total Bonus (USD)</div>
                <div className="number fz-32">316</div>
                <div className="compare">
                  <div className="percent-block cl-027A48">
                    <div className="icon-svg">
                      <ArrowUpOutlined />
                    </div>
                    <div className="percent">20%</div>
                  </div>
                  <div className="text">vs yesterday</div>
                </div>
              </div>

              <div className="one-item">
                <div className="title">Profit (USD)</div>
                <div className="number fz-32">316</div>
                <div className="compare">
                  <div className="percent-block cl-027A48">
                    <div className="icon-svg">
                      <ArrowUpOutlined />
                    </div>
                    <div className="percent">20%</div>
                  </div>
                  <div className="text">vs yesterday</div>
                </div>
              </div>
            </div>
          </div>
          <div className="right"><LineChart /></div>
        </div>

        <div className="pie-chart-block">
          <div className="left">
            <div className="list-show">
              <div className="one-item">
                <div className="title">Members</div>
                <div className="number fz-32">1,210</div>
                <div className="compare">
                  <div className="percent-block">
                    <div className="icon-svg">
                      <ArrowDownOutlined />
                    </div>
                    <div className="percent">20%</div>
                  </div>
                  <div className="text">vs yesterday</div>
                </div>
              </div>

              <div className="one-item mt-24">
                <div className="title">Members active</div>
                <div className="number fz-32">121</div>
                <div className="compare">
                  <div className="percent-block">
                    <div className="icon-svg">
                      <ArrowDownOutlined />
                    </div>
                    <div className="percent">20%</div>
                  </div>
                  <div className="text">vs yesterday</div>
                </div>
              </div>

            </div>
          </div>
          <div className="right">
            <div className="one-chart"><PieChart1 /></div>
            <div className="one-chart"><PieChart2 /></div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckInChart;
