import { API_URL } from '../env';

const checkRp = (res: any) => {
  const errmess = ['Signature verification failed', 'Wrong number of segments', 'Malformed UTF-8 characters', 'Expired token']
  if (errmess.includes(res.message)) {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
    window.location.href = '/login';
    return false
  } else {
    return res
  }
}

export const mixFnum = (number : number) => {
  return new Intl.NumberFormat('vi-VN', { maximumSignificantDigits: 3 }).format(number);
}

export const mixGetV1 = async (url : string, params = {} as any) => {
  Object.keys(params).map((isKey, index) => {
    if (params[isKey] || params[isKey] === 0) {
      if (Array.isArray(params[isKey])) {
        params[isKey].map((val: any) => {
          url = `${url}${index === 0 ? '?' : '&'}${isKey}=${val}`
          return true
        })
      } else {
        url = `${url}${index === 0 ? '?' : '&'}${isKey}=${params[isKey]}`
      }
    }
    return null
  })
  const response = await fetch(`${API_URL}${url}`, {
    headers: {
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });
  const temp = await response.json()
  return checkRp(temp)
}

export const mixPostV1 = async (url: string, param: any) => {
  try {
    const formData = new FormData()
    Object.keys(param).map((keyParam : string) => {
      formData.append(keyParam, param[keyParam])
      return true
    })
    const response = await fetch(`${API_URL}${url}`, {
      method: 'POST',
      headers: {
        'accept': 'application/json',
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
      body: formData,
    })
    const temp = await response.json()
    return checkRp(temp)
  } catch (error) {
    return {}
  }
}

export const mixPutV1 = async (url: string, param: any) => {
  const response = await fetch(`${API_URL}${url}`, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(param),
  })
  const temp = await response.json()
  return checkRp(temp)
}

export const mixPutV2 = async (url: string, param: any) => {
  const formData = new FormData()
  Object.keys(param).map((keyParam : string) => {
    formData.append(keyParam, param[keyParam])
    return true
  })
  const response = await fetch(`${API_URL}${url}`, {
    method: 'PUT',
    headers: {
      'accept': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: formData,
  })
  const temp = await response.json()
  return checkRp(temp)
}

export const mixFormDataV1 = async (url: string, param: any) => {
  const formData = new FormData()
  Object.keys(param).map((keyParam : string) => {
    formData.append(keyParam, param[keyParam])
    return true
  })
  const response = await fetch(`${API_URL}${url}`, {
    method: 'POST',
    headers: {
      'accept': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: formData,
  })
  const temp = await response.json()
  return checkRp(temp)
}

export const mixDelV1 = async (url: string, param: {}) => {
  const response = await fetch(`${API_URL}${url}`, {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
    body: JSON.stringify(param),
  })
  const temp = await response.json()
  return checkRp(temp)
}

export const mixTreeIds = (arr = [] as any) => {
  const res = [] as any
  arr.map( (item: any) => {
    if (typeof item === 'number' && !isNaN(item)) {
      res.push(item)
    } else {
      const temp = item.split('-')
      res.push(parseInt(temp[1]))
    }
    return true
  })
  return res
}

export const mixGender = (valx: any) => {
  const list = [
    {
      value: 1,
      title: 'Nam',
    },
    {
      value: 0,
      title: 'Nữ',
    },
    {
      value: 2,
      title: 'Không xác định',
    }
  ]
  const obs = {} as any
  list.map(val => {
    obs[`s${val.value}`] = val
    return true
  })
  if (valx || valx === 0) {
    return obs[`s${valx}`] || null
  }
  return list
}
