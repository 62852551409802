import React, { useState } from 'react';
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { Input, Form, Button, message } from 'antd';
import { Eye, EyeSlash, LockCircle, ProfileCircle, Unlock } from 'iconsax-react';
import { mixPostV1 } from '../../services/mixin';

const Home: React.FC = () => {
  const [form] = Form.useForm()
  const navigate = useNavigate()
  const [messageApi, contextHolder] = message.useMessage()
  const [loading, setLoading] = useState(false)

  const onFinish = async (values: any) => {
    setLoading(true)
    const res  = await mixPostV1('/api/webAdmin/loginOwner.php', values)
    if (res.status === 1) {
      setLoading(false)
      localStorage.setItem('user', JSON.stringify({
        ...res.data,
        type: res.type,
        permission: res.dataPermission ? res.dataPermission : null
      }))

      if(res.data.role_id === 3 || res.data.role_id === 4) {
        createToken(res, res.data.role_id)
      } else {
        navigate('/login/otp')
      }
    } else {
      setLoading(false)
      messageApi.open({
        type: 'error',
        content: res.message,
      })
    }
  }

  const createToken = async (val: any, roleId: number) => {
    const param = {
      owner_id: val.data.id,
      store_id: val.store_id,
    }
    const res = await mixPostV1('/api/webAdmin/createTokenAdmin.php', param)
    if (res.status === 1) {
      localStorage.setItem('token', res.token)

      if (roleId === 3) {
        window.location.href = '/store-list'
      } else {
        window.location.href = '/'
      }
    } else {
      messageApi.open({
        type: 'error',
        content: res.message,
      })
    }
  }

  const onFinishFailed = (errorInfo: any) => {
    console.log('Failed:', errorInfo)
  }

  return (
    <>
      {contextHolder}
      <div className='login-page'>
        <div className="bg-img">
          <img src="/images/checkin/bg-checkin-op1.jpg" alt="" />
        </div>
        <div className="img-logo">
          <img src="/images/logo.png" alt="" />
        </div>
        <div className="content-block mw-464">
          <div className="title">Welcome Back</div>
          <div className="sum">Log in to manage now!</div>
          <Form
            className="form-login-block"
            form={form}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off"
            layout="vertical"
          >
            <div className="input-icon-block">
              <Form.Item
                name="username"
                rules={[{ required: true }]}
              >
                <div className="h-44 b-r">
                  <Input placeholder="username" prefix={<ProfileCircle variant="Bulk" />} />
                </div>
              </Form.Item>
            </div>

            <div className="input-icon-block mb-48">
              <Form.Item
                name="password"
                rules={[{ required: true }]}
              >
                <div className="h-44 b-r">
                  <Input.Password
                    placeholder="Password"
                    autoComplete='new-password'
                    prefix={<LockCircle variant="Bulk" />}
                    iconRender={(visible) => (visible ? <Eye variant="Bulk" /> : <EyeSlash variant="Bulk" />)}
                  />
                </div>
              </Form.Item>
            </div>

            <Form.Item>
              <div className="mb-0 btn-block">
                <Button
                  className="btn t-btn-primary h-44"
                  type="primary"
                  loading={loading}
                  htmlType="submit"
                  icon={<Unlock variant="Bulk" />}
                  style={{ gap: 0 }}
                >Login now</Button>
                <div className="text-reset">
                  Forgot password?
                  {/* <Button className='btn-reset rs-btn' htmlType="button" onClick={onReset}>
                    Reset now
                  </Button> */}
                  <Button className='btn-reset rs-btn' htmlType="button" onClick={() => navigate('/login/forgot-password')}>
                    Reset now
                  </Button>
                </div>
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>
    </>
  );
}

export default Home;
