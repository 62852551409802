import React from 'react'
import './ChooseStoreToGetDataRealTimeGrid.scss'

interface Props {
  children?: React.ReactNode
}

const ChooseStoreToGetDataRealTimeGrid:React.FC<Props> = ({ children }) => {
  return (
    <div className="choose-store-grid">
      { children }
    </div>
  )
}

export default ChooseStoreToGetDataRealTimeGrid
