import React, { useEffect } from 'react';
// import { Helmet } from "react-helmet";
import './style.scss';
import { Link, useNavigate } from 'react-router-dom';

const NotificationFail: React.FC = () => {


  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      navigate('/checkin/notification-locked')
    }, 5000)
  });

  return (
    <div className='checkin-page checkin-notification'>
      <div className="bg-img">
        <img src="/images/checkin/ellipse-bg.png" alt="" />
      </div>
      <div className="content-block mw-850">
        <div className="img-box">
          <img src="/images/checkin/fail-icon.png" alt="" />
        </div>
        <div className="title cl-F9D361">Uh-oh, Check-in Failed!</div>
        <div className="sum cl-fff">It seems we're having trouble<br></br>processing your check-in.</div>
        <Link to="/checkin-ai" className="btn btn-border-text-primary h-68">Retry Verification</Link>
      </div>
    </div>
  );
}

export default NotificationFail;
