import React, { useEffect } from 'react';
// import { Helmet } from "react-helmet";
import './style.scss';
import { CheckOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import BgVideo from './VideoBackground';

const CheckinAiProgress: React.FC = () => {


  const videoSource = '/images/checkin/bg-video.mp4';

  const navigate = useNavigate()

  useEffect(() => {
    setTimeout(() => {
      navigate('/checkin/notification-fail')
    }, 5000)
  });

  return (
    <div className='checkin-page checkin-ai-progress'>
      <div className="bg-img-checkin-progress">
        {/* <img src="/images/checkin/bg-page-checkin-progress.png" alt="" /> */}
        {/* <BgVideo videoSrc="/images/checkin/bg-video.mp4" posterSrc="/images/checkin/bg-page-checkin-progress.png" /> */}
        <BgVideo src={videoSource} />
      </div>
      <div className="bg-img-checkin-progress-ellipse">
        <img src="/images/checkin/bg-ellipse.png" alt="" />
      </div>
      <div className="bg-img">
        <img src="/images/checkin/bg-checkin-progress.png" alt="" />
      </div>
      <div className="content-block mw-801">
        <div className="title mb-24">Setup eKYC</div>

        <div className="sum">
          04 steps quick & easy
        </div>

        <div className="list-setup list-loading">
          <div className="one-item">
            <div className="number"><CheckOutlined /></div>
            <div className="text">Position Correctly</div>
          </div>
          <div className="one-item">
            <div className="number"><CheckOutlined /></div>
            <div className="text">Rotate left</div>
          </div>
          <div className="one-item checkin-loading">
            <div className="number">
              <div className="icon-svg">
                <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M16.0002 2.66675V8.00008M16.0002 24.0001V29.3334M6.57349 6.57336L10.3468 10.3467M21.6536 21.6534L25.4269 25.4267M2.66699 16.0001H8.00033M24.0002 16.0001H29.3336M6.57349 25.4267L10.3468 21.6534M21.6536 10.3467L25.4269 6.57336" stroke="#F9D361" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              </div>
            </div>
            <div className="text">Rotate left</div>
          </div>
          <div className="one-item op-40">
            <div className="number">4</div>
            <div className="text cl-fff">Rotate right</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default CheckinAiProgress;
