import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

import CheckoutForm from "./CheckoutForm";
import { mixPostV1 } from '../../../services/mixin';
import "./App.scss";

// Make sure to call loadStripe outside of a component’s render to avoid
// recreating the Stripe object on every render.
// This is a public sample test API key.
// Don’t submit any personally identifiable information in requests made with this key.
// Sign in to see your own test API key embedded in code samples.

const AppTripe = (props) => {
  const { onChange } = props
  const[stripePromise, setStripePromise] = useState(null)
  let waitx = false
  const gKeyStripe = async () => {
    if (waitx) { return }
    waitx = true
    const res = await mixPostV1('/api/webAdmin/gKeyStripe.php', {})
    waitx = false

    if (res.status === 1) {
      setStripePromise(loadStripe(res.data.public_key))
    }
  }
  useEffect(() => { gKeyStripe() }, [])
  return (
    <div className="Appx">
      {stripePromise !== null &&
        <Elements stripe={stripePromise}>
          <CheckoutForm onChange={(e) => onChange(e)} />
        </Elements>
      }
    </div>
  );
}
export default AppTripe
