import React, { useState } from 'react'
import Layout from '../../components/layouts/Layout'
import BreadcrumbBlock from '../../components/BreadcrumbBlock/BreadcrumbBlock'
import { Link } from 'react-router-dom'
import { Flex } from 'antd'
import { Home2 } from 'iconsax-react'
import GameLayout from './Components/GameLayout'
import Players from './Components/Players'
import Results from './Components/Results'
import GameBar from './Components/Game'
import AddPlayerModal from './Components/AddPlayer'
import SendMessageModal from '../../components/Modal/SendMessage'

const LuckyGame: React.FC = () => {
  const [gamePlaying, setGamePlaying] = useState(false)
  const [showSendSMSModal, setShowSendSMSModal] = useState(false)
  const [showAddPlayerModal, setShowAddPlayerModal] = useState(false)
  const [players, setPlayers] = useState([] as any)
  const [winners, setWinners] = useState([] as any)
  const [showPlayerListOnMobile, setShowPlayerListOnMobile] = useState(false)
  const [showResultListOnMobile, setShowResultListOnMobile] = useState(false)

  // SET WINNER
  const setWinner = (winner: any) => {
    const newWinner = {...winner, total: 1}
    const existWinnerIndex = winners.findIndex((item: any) => item.id === newWinner.id)

    if (existWinnerIndex !== -1) {
      const newWinners = [...winners]
      newWinners[existWinnerIndex] = {
        ...newWinners[existWinnerIndex],
        total: newWinners[existWinnerIndex].total + 1,
      }
      setWinners(newWinners)
    } else {
      setWinners([
        ...winners,
        newWinner,
      ])
    }
  } // END SET WINNER

  return (
    <>
      <Layout
        className='match-height'
        contentClassName="lucky-game-content"
      >
        <BreadcrumbBlock
          items={[
            { title: <Flex align='center' gap={16}><Home2 variant="Bulk" /><Link to="/">CMD Tech</Link></Flex>, },
            { title: 'Store Game', },
            { title: 'Lucky Game', },
          ]}
          title='Lucky Game'
          description=''
        />

        <GameLayout
          players={
            <Players
              title='Player'
              list={players}
              playing={gamePlaying}
              className={`${showPlayerListOnMobile ? 'show' : ''}`}
              onRemove={(id) => setPlayers(players.filter((item: any) => item.id !== id))}
              onRemoveAll={() => setPlayers([])}
              onAddPlayer={() => setShowAddPlayerModal(true)}
              onClose={() => setShowPlayerListOnMobile(false)}
            />
          }
          game={<GameBar
            disabledPlay={players.length === 0}
            players={players}
            onPlay={() => setGamePlaying(true)}
            onFinish={() => setGamePlaying(false)}
            onConfirm={(winner) => {
              setWinner(winner)
            }}
            onPlayerClick={() => setShowPlayerListOnMobile(true)}
            onResultClick={() => setShowResultListOnMobile(true)}
          />}
          results={
            <Results
              winners={winners}
              title='Winner list'
              playing={gamePlaying}
              empty={winners.length === 0}
              className={`${showResultListOnMobile ? 'show' : ''}`}
              onClose={() => setShowResultListOnMobile(false)}
              onSendSMS={() => { setShowSendSMSModal(true) }}
            />
          }
        />
      </Layout>

      {/* MODAL SEND MESSAGE */}
      {/* <SendMessageModal
        customers={winners}
        isShow={showSendSMSModal}
        onPopupClose={() => setShowSendSMSModal(false)}
        onSend={() => {
          setPlayers([])
          setWinners([])
        }}
      /> */}

      {/* MODAL ADD PLAYER */}
      <AddPlayerModal
        isShow={showAddPlayerModal}
        players={players}
        onPopupClose={() => setShowAddPlayerModal(false)}
        onAddPlayer={(players) => {
          setShowAddPlayerModal(false)
          setPlayers(players)
        }}
      />
    </>
  )
}

export default LuckyGame
