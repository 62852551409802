import { Link, useNavigate } from 'react-router-dom'
import './style.scss'
import { Input, Button, notification, Form } from 'antd'
import { useRef, useState } from 'react'
import { mixPostV1 } from '../../services/mixin'
import type { FormInstance, GetProp } from 'antd';
import type { OTPProps } from 'antd/es/input/OTP';

const CheckinOTP: React.FC = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}')
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate()
  const inputRef: any = useRef(null)

  const onChange: GetProp<typeof Input.OTP, 'onChange'> = (text) => {
    // console.log('onChange:', text)
    // setCodes(text)
  }

  const sharedProps: OTPProps = {
    onChange,
  }

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification()
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Voucher created!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    })
  }

  // FORM SUBMIT
  const formRef = useRef<FormInstance>(null)
  const onSubmitForm = () => {
    if (formRef.current) {
      formRef.current.submit()
    }
  }
  const onFinish = (values: any) => {
    verifyCode(values)
  };

  const verifyCode = async (values: any) => {
    setLoading(true)
    try {
      const params = {
        owner_id: user.id,
        passcode: values.otp,
      }
      const res = await mixPostV1('/api/webAdmin/verifyPasscode.php', params)

      if (res.status === 1) {
        navigate('/login/choose-store')
        // if (user.type === 2) {
        //   createToken()
        // } else {
        //   navigate('/login/choose-store')
        // }
      } else {
        openNotification('error', res.message)
        if (formRef.current) {
          formRef.current.resetFields()
        }
        if (inputRef.current) {
          inputRef.current.focus()
        }
        setLoading(false)
      }
    } catch (error) {
      setLoading(false)
      openNotification('error', 'Oops, something went wrong!')
    }
  }

  // const createToken = async () => {
  //   const storeId = stores[0].store_id
  //   const param = {
  //     owner_id: user.id,
  //     store_id: storeId,
  //   }
  //   const res = await mixPostV1('/api/webAdmin/createTokenAdmin.php', param)
  //   if (res.status === 1) {
  //     localStorage.setItem('token', res.token)
  //     window.location.href = '/'
  //   } else {
  //     openNotification('error', res.message)
  //   }
  // }
  // END SUBMIT FORM

  // GET STORES
  // const [stores, setStores] = useState([] as any)
  // const getStore = async () => {
  //   const res = await mixPostV1('/api/webAdmin/gComboStoreByOwner.php', {owner_id: user.id})
  //   if (res.status === 1) {
  //     setStores(res.data)
  //   }
  // }
  // useEffect(() => {
  //   getStore()

  //   if (inputRef.current) {
  //     inputRef.current.focus()
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])
  // END GET STORES

  return (
    <>
      { contextHolder }

      <div className='login-page'>
        <div className="bg-img">
          <img src="/images/checkin/bg-checkin-op1.jpg" alt="" />
        </div>
        <div className="img-logo">
          <img src="/images/logo.png" alt="" />
        </div>
        <div className="content-block mw-464">
          <Link to="/login" className='btn-back'>Back</Link>

          <div className="title mb-24">Verify</div>

          <div className="sum">
            Enter your { Number(user.role_id) === 1 ? 'admin' : Number(user.role_id) === 2 ? 'owner' : 'user' } code
          </div>

          <div className="ctrl-group-gender mb-24">
            <div className="label">Your code</div>
            <Form
              ref={formRef}
              layout='vertical'
              className='rs-form'
              onFinish={onFinish}
            >
              <Form.Item
                name='otp'
                rules={[{ required: true, message: 'Please type your code!' }]}
              >
                <Input.OTP
                  ref={inputRef}
                  length={4}
                  {...sharedProps}
                />
              </Form.Item>
            </Form>
          </div>

          <Button
            loading={loading}
            className="btn t-btn-primary h-80 t-fz-24"
            onClick={onSubmitForm}
          >Verify Code</Button>
        </div>
      </div >
    </>
  )
}

export default CheckinOTP
