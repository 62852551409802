import React, { useEffect, useRef, useState } from 'react'
import SettingLayout from './Components/SettingLayout'
import { Button, Flex, Form, FormInstance, FormProps, Input, notification } from 'antd'
import { AddSquare, Code, Gameboy, Magicpen } from 'iconsax-react'
import { mixPostV1 } from '../../services/mixin'

type DevicesType = {
  device_id?: string
  device_name?: string
}
type FieldType = {
  devices?: DevicesType[]
}

// CHECK ROLE = 'USER'
const user = JSON.parse(localStorage.getItem('user') || '{}')
const isOwner = user.role_id === 2 || user.role_id === 1
const permission = user.permission
const settingRole = permission ? permission.find((item: any) => item.function_id === 7) : null

const SettingDevice:React.FC = () => {
  // UPDATE DEVICES
  const formRef = useRef<FormInstance<FieldType>>(null)
  const onSubmitForm = () => {
    if (formRef && formRef.current) {
      formRef?.current?.submit()
    }
  }

  const [confirmLoading, setConfirmLoading] = useState(false)
  const onFinish: FormProps<FieldType>["onFinish"] = async (values: any) => {
    const jetsonNano = values.devices[0]
    const _devices = values.devices.filter((item: any, index: number) => index !== 0)

    const devicesJSON = JSON.stringify(_devices)
    const params = {
      camera_ip: jetsonNano.device_id,
      array_devices: devicesJSON
    }
    const res = await mixPostV1('/api/webAdmin/updateDevicesOwner.php', params)

    if (res.status) {
      setConfirmLoading(false)
      getDevices()
      openNotification()
    } else {
      setConfirmLoading(false)
      openNotification('error', 'Something went wrong!')
    }
  }
  // END UPDATE DEVICES
  let waitx = false
  const getDevices = async () => {
    if (waitx) { return }
    waitx = true
    const params = {
      page: 1,
      limit: 10000,
    }
    try {
      const res = await mixPostV1('/api/webAdmin/gListDevicesOwner.php', params)
      waitx = false
      if (res.status === 1) {
        const data: DevicesType[] = []
        const jetsonNano = res.CameraInfo?.camera_ip

        if (jetsonNano) {
          data.push({
            'device_id': jetsonNano,
            'device_name': 'Jetson Nano',
          })
        }
        res.data.map((item: DevicesType) => data.push({
          'device_id': item.device_id,
          'device_name': item.device_name,
        }))
        const formattedData: FieldType = { devices: data }

        if (formRef.current) {
          formRef.current.setFieldsValue(formattedData)
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    getDevices()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = 'Devices updated!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  return (
    <SettingLayout current="device">
      { contextHolder }
      <div className="setting-content setting-content--update setting-device">
        <div className="mw-687">
          <Flex
            vertical
            gap={8}
          >
            {/* <div className="title-12 text-idle font-bold">Device</div> */}
            <Form
              ref={formRef}
              name="device-form"
              onFinish={onFinish}
              layout='vertical'
              autoComplete="off"
              className={`rs-form form-gap-12 ${isOwner ? 'is-owner' : ''}`}
            >
              {/* <Flex
                gap={8}
                align='center'
                className={'jetson-nano'}
              >
                <Form.Item
                  name={[name, 'device_name']}
                  rules={[{required: true, message: 'This field must be filled!'}]}
                >
                  <Input
                    placeholder="Device name"
                    className={`rs-input ${index === 0 ? 'jetson-nano__device-name' : ''}`}
                    prefix={<Gameboy size={18} variant="Bulk" />}
                    disabled={
                      (index === 0) || (settingRole && settingRole.is_edit === 0)
                    }
                  />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'device_id']}
                  rules={[{required: true, message: 'This field must be filled!'}]}
                >
                  <Input
                    placeholder={index === 0 ? 'ID Jetson Nano' : 'Device ID'}
                    className="rs-input"
                    disabled={settingRole && settingRole.is_edit === 0}
                    prefix={<Code size={18} variant="Bulk" />}
                  />
                </Form.Item>
              </Flex> */}

              <Form.List name="devices">
                {(fields, { add, remove }) => (
                  <>
                    {fields.map(({ key, name, ...restField }, index) => (
                      <Flex
                        key={key}
                        gap={8}
                        align='center'
                        className={`${index === 0 ? 'jetson-nano' : ''}`}
                      >
                        <Form.Item
                          {...restField}
                          name={[name, 'device_name']}
                          rules={[{required: true, message: 'This field must be filled!'}]}
                        >
                          <Input
                            placeholder="Device name"
                            className={`rs-input ${index === 0 ? 'jetson-nano__device-name' : ''}`}
                            prefix={<Gameboy size={18} variant="Bulk" />}
                            disabled={
                              (index === 0) || (settingRole && settingRole.is_edit === 0) || isOwner
                            }
                          />
                        </Form.Item>
                        <Form.Item
                          {...restField}
                          name={[name, 'device_id']}
                          rules={[{required: true, message: 'This field must be filled!'}]}
                        >
                          <Input
                            placeholder={index === 0 ? 'ID Jetson Nano' : 'Device ID'}
                            className="rs-input"
                            disabled={(settingRole && settingRole.is_edit === 0)  || isOwner}
                            prefix={<Code size={18} variant="Bulk" />}
                          />
                        </Form.Item>
                        {
                          !isOwner && (
                            index === 0
                              ?
                                <Button
                                  className={'rs-button button-magic-pen default-outlined'}
                                  icon={<Magicpen size="20" variant="Bulk" />}
                                />
                              :
                                <Button
                                  className={'rs-button button-magic-pen danger-outlined'}
                                  icon={<Magicpen size="20" variant="Bulk" />}
                                  disabled={settingRole && settingRole.is_delete === 0}
                                  onClick={() => remove(name)}
                                />
                          )
                        }
                      </Flex>
                    ))}

                    {
                      !isOwner &&
                      <Button
                        type='primary'
                        icon={<AddSquare size="20" variant="Bulk" />}
                        disabled={settingRole && settingRole.is_create === 0}
                        className='rs-button button-duplicate primary-outlined'
                        onClick={() => add()}
                      >Add new</Button>
                    }
                  </>
                )}
              </Form.List>
            </Form>
          </Flex>
        </div>

        {
          !isOwner &&
            <Button
              className='rs-button button-update'
              type='primary'
              loading={confirmLoading}
              disabled={settingRole && settingRole.is_edit === 0}
              onClick={onSubmitForm}
            >Update</Button>
        }
      </div>
    </SettingLayout>
  )
}

export default SettingDevice
