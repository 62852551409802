import React from 'react';
// import { Helmet } from "react-helmet";
import './style.scss';
import { Link } from 'react-router-dom';

const Role: React.FC = () => {

  return (
    <div className='checkin-page checkin-index'>
      <div className="bg-img">
        <img src="/images/checkin/bg-checkin-op1.jpg" alt="" />
      </div>
      <div className="content-block mw-474">
        <div className="title">Embrace<br></br>Your Role!</div>
        <div className="sum">Your contribution matters</div>
        <div className="btn-block">
          <Link to="/checkin/checkin-ai-staff" className="btn t-btn-primary">Check-in</Link>
          <Link to="#" className="btn t-btn-df">Register</Link>
        </div>
      </div>
      <Link to="/checkin/chart" className="link-dashboard">
        <div className="icon-svg">
          <img src="/images/checkin/icon-db.png" alt="img" />
        </div>
        <div className="text">Dashboard</div>
      </Link>
    </div>
  );
}

export default Role;
