import React, { useEffect, useState } from 'react'
import './RandomInputItem.scss'
import { Button, Input } from 'antd'
import { Magicpen } from 'iconsax-react'
import { displayPartsToString } from 'typescript'

interface Props {
  text?: string,
  showDelete?: boolean,
  onDelete?: () => void,
  onChange?: any,
}

const RandomInputItem: React.FC<Props> = (props) => {
  const {  text = '', showDelete = true, onDelete, onChange } = props
  const [inputValue, setInputValue] = useState(text)

  useEffect(() => {
    setInputValue(text)
  }, [text])

  const deleteItem = () => {
    if (onDelete) {
      onDelete()
    }
  }

  return (
    <div className="random-input-item">
      <Input
        value={inputValue}
        placeholder='Input anything'
        className='rs-input'
        readOnly={!showDelete}
        onChange={(e) => onChange(e.target.value)}
      />
      {
        showDelete &&
          <Button
            icon={<Magicpen size={20} variant="Bulk" />}
            className='rs-button primary-outline'
            onClick={() => deleteItem()}
          />
      }
    </div>
  )
}

export default RandomInputItem
