import React, { useRef, useEffect } from 'react';

const VideoPlayer: React.FC<{ src: string }> = ({ src }) => {
  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(error => {
        // Xử lý lỗi nếu có
        console.error('Autoplay failed: ', error);
      });
    }
  }, []);

  return <video ref={videoRef} src={src} muted loop autoPlay />;
};

export default VideoPlayer;
