import { useEffect, useState, useRef } from 'react';
import { Button, Checkbox, GetProp , TimePicker, Form, Input, FormInstance, notification } from 'antd';
import { AddSquare, Clock, Magicpen, WristClock } from 'iconsax-react';
import { mixPostV1 } from '../../../services/mixin';
// const { RangePicker } = TimePicker;

interface Props {
  listDay?: any
  isSubmit?: any
  typeWork?: any
}

// CHECK ROLE = 'USER'
const user = JSON.parse(localStorage.getItem('user') || '{}')
const permission = user.permission
const settingRole = permission ? permission.find((item: any) => item.function_id === 7) : null

const SelectedDay: React.FC<Props> = (props) => {
  const { listDay, isSubmit, typeWork } = props
  const [checkList, setCheckList] = useState<any>([])
  const [disabledButton, setDisabledButton] = useState<any>({})

  useEffect(() => {
    const temp = [] as any
    const daysObj:any = {}
    listDay.map((item: any) => {
      if (item.active === 1) {
        temp.push(item.id)
        daysObj[item.name] = item.listShift
      }
      return true
    })
    setCheckList(temp)
    setTimesFunc(daysObj)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listDay])

  const onFinish = async (values: any) => {
    const temp = [] as any
    Object.keys(values).map((keyday: any) => {
      if (values[keyday]) {
        const temp2 = [] as any
        values[keyday].map((subItem: any) => {
          temp2.push({
            shift_name: Number(subItem.shift_name.split('Shift ')[1]),
            // from_time: subItem.time[0].format('HH:mm'),
            // to_time: subItem.time[1].format('HH:mm')
            from_time: subItem.from_time.format('HH:mm'),
            to_time: subItem.to_time.format('HH:mm')
          })
          return true
        })
        const findId = listDay.find((item: any) => item.name === keyday)
        temp.push({
          id: findId.id,
          listShift: temp2
        })
      }
      return true
    })
    const res = await mixPostV1('/api/webAdmin/updateShiftByDay.php', {
      array_shift: JSON.stringify(temp)
    })
    if (res.status === 1) {
      openNotification('success', res.message)
    } else {
      openNotification('error', res.message)
    }
  };
  const onChangeListDay: GetProp<typeof Checkbox.Group, 'onChange'> = (checkedValues) => {
    setCheckList(checkedValues)
  };

  useEffect(() => {
    if (isSubmit && isSubmit > 0) {
      onSubmitForm()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmit])

  const formRef = useRef<FormInstance>(null)
  const onSubmitForm = () => {
    if (formRef && formRef.current && Number(typeWork) === 1) {
      formRef?.current?.submit()
    }
  }

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = '' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  const changeAll = (e: any) => {
    const temp = formRef?.current?.getFieldsValue()
    const valMon = temp?.Mon
    Object.keys(temp).map((keyday: any) => {
      temp[keyday] = valMon
      return true
    })
    setTimesFunc(temp)
    formRef?.current?.setFieldsValue(temp)
  }

  const getName = (dayName: any) => {
    let res = 'Shift 1'
    try {
      if (formRef && formRef.current) {
        const temp = formRef?.current?.getFieldsValue() as any
        if (temp[dayName]) {
          res = `Shift ${temp[dayName].length + 1}`
        }
      }
    } catch (error) {
      res = 'Shift 1'
    }
    return res
  }

  const hasMonItem = () => {
    let res = false
    const temp = formRef?.current?.getFieldsValue() as any
    if (temp['Mon'] && temp['Mon'].length > 0) {
      res = true
    }
    return res
  }

  const setNameDf = (dayName : any) => {
    const data = formRef?.current?.getFieldsValue() as any
    if (data && data[dayName]) {
      const temp = [] as any
      data[dayName].map((val: any, index: number) => {
        temp.push({
          ...val,
          shift_name: `Shift ${index + 1}`
        })
        return true
      })
      data[dayName] = temp
      formRef?.current?.setFieldsValue(data)
    }
  }

  const [times, setTimes] = useState({} as any)
  const onValuesChange = (changedValues: any, allValues: any) => {
    setTimesFunc(allValues)
  }

  const setTimesFunc = (data: any) => {
    const temp:any = {}
    // eslint-disable-next-line array-callback-return
    Object.keys(data).map((keyday: any) => {
      if (data[keyday]) {
        const timeArr = [] as any
        data[keyday].map((subItem: any) => {
          const {from_time, to_time} = subItem
          if (!from_time && !to_time) return false

          timeArr.push({
            from: from_time ? from_time?.format('HH:mm') : '',
            to: to_time ? to_time?.format('HH:mm') : ''
          })
          return true
        })
        const findId = listDay.find((item: any) => item.name === keyday)
        temp[findId.id] = timeArr
      }
    })
    setTimes(temp)
  }

  useEffect(() => {
    const temp:any = {}
    // eslint-disable-next-line array-callback-return
    Object.keys(times).map((keyday: any) => {
      if (times[keyday]) {
        const _times = times[keyday]
        const firstShiftFromHour = Number(_times[0]?.from?.split(':')[0])
        const lastShiftToTime = _times[_times.length - 1]?.to
        const lastShiftFromHour = Number(_times[_times.length - 1]?.from?.split(':')[0])
        const lastShiftToHour = lastShiftToTime ? Number(lastShiftToTime.split(':')[0]) : lastShiftFromHour

        const condition = firstShiftFromHour - lastShiftToHour === 1 || firstShiftFromHour === lastShiftToHour || firstShiftFromHour - lastShiftToHour === -23
        temp[keyday] = condition
      }
    })
    setDisabledButton(temp)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [times])

  const disabledHours = (id: number, minHour: number, maxHour?: number) => {
    if (isNaN(minHour)) return []
    const conditionHour = maxHour ? maxHour : 0
    const hours: any = []
    const _times = times[id]

    const firstShiftFromHour = Number(_times[0]?.from?.split(':')[0])
    const firstShiftFromMinute = Number(_times[0]?.from?.split(':')[1])
    const lastShiftToTime = _times[_times.length - 1]?.to
    const lastShiftFromHour = Number(_times[_times.length - 1]?.from?.split(':')[0])
    const lastShiftToHour = lastShiftToTime !== '' ? Number(lastShiftToTime.split(':')[0]) : lastShiftFromHour
    const condition = lastShiftToHour < firstShiftFromHour
    const limitHour = firstShiftFromMinute === 0 ? firstShiftFromHour : firstShiftFromHour + 1

    if (condition) {
      for (let i = lastShiftToHour - 1; i >= 0; i--) {
        hours.push(i)
      }
      for (let i = limitHour; i < 24; i++) {
        hours.push(i)
      }
    } else {
      for (let i = minHour - 1; i >= conditionHour; i--) {
        hours.push(i)
      }
    }
    return hours
  }

  const disabledMinutes = (id: number, selectedHour: number, minHour: any, minMinute: any, isToTime?: boolean) => {
    const _times = times[id]
    const minutes: any = []
    const firstShiftHour = Number(_times[0]?.from?.split(':')[0])
    const firstShiftMinute = Number(_times[0]?.from?.split(':')[1])

    if (selectedHour === minHour) {
      for (let i = minMinute; i >= 0; i--) {
        minutes.push(i)
      }
    }
    if (selectedHour === firstShiftHour && isToTime && _times.length > 1) {
      for (let i = firstShiftMinute; i < 60; i++) {
        minutes.push(i)
      }
    }
    return minutes
  }

  const disabledFromTime = (id: number, minTime: string) => {
    const minHour = Number(minTime?.split(':')[0])
    const minMinute = Number(minTime?.split(':')[1])
    const limitMinHour = minMinute === 59 ? minHour + 1 : minHour

    return {
      disabledHours: () => disabledHours(id, limitMinHour),
      disabledMinutes: (selectedHour: any) => disabledMinutes(id, selectedHour, minHour, minMinute)
    }
  }

  const disabledToTime = (id: number, minTime: string, maxTime?: string) => {
    const minHour = Number(minTime?.split(':')[0])
    const minMinute = Number(minTime?.split(':')[1])
    const maxHour = Number(maxTime?.split(':')[0])
    const maxMinute = Number(maxTime?.split(':')[1])
    const limitMinHour = minMinute === 59 ? minHour + 1 : minHour
    const limitMaxHour = maxMinute > 0 ? maxHour + 1 : maxHour

    return {
      disabledHours: () => disabledHours(id, limitMinHour, limitMaxHour),
      disabledMinutes: (selectedHour: any) => disabledMinutes(id, selectedHour, minHour, minMinute, true)
    }
  }

  return (
    <div className="always-open">
      { contextHolder }
      <Checkbox.Group value={checkList} onChange={onChangeListDay} className="list-check-days">
        {
          listDay.map((item: any) => {
            return (
              <Checkbox
                key={item.id}
                className="one-check"
                value={item.id}
                disabled={settingRole && settingRole.is_edit === 0}
              >{item.name}</Checkbox>
            )
          })
        }
      </Checkbox.Group>
      {/* <div className="list-check-days">
        <Checkbox className="one-check" onChange={onChange}>Mo</Checkbox>
        <Checkbox className="one-check" onChange={onChange}>Tu</Checkbox>
        <Checkbox className="one-check" onChange={onChange}>We</Checkbox>
        <Checkbox className="one-check" onChange={onChange}>Thu</Checkbox>
        <Checkbox className="one-check" onChange={onChange}>Fri</Checkbox>
        <Checkbox className="one-check" onChange={onChange}>Sa</Checkbox>
        <Checkbox className="one-check" onChange={onChange}>Su</Checkbox>
      </div> */}

      <div className="time-setup-day-block">
        <Form
          onFinish={onFinish}
          autoComplete="off"
          ref={formRef}
          onValuesChange={onValuesChange}
        >
          {
            listDay.map((item: any) => {
              return (
                <>
                  {checkList.includes(item.id) === true &&
                    <div className="item-time-setup-day" key={item.id}>
                      <div className="title-top fix-color t-fz-12 fw-600">{item.name}</div>
                      <div className='form-list'>
                        <Form.List name={item.name} initialValue={item.listShift || []}>
                          {(fields, { add, remove }) => (
                            <>
                              {fields.map(({ key, name, ...restField }, index: number) => (
                                <div className="one-input-time" key={index}>
                                  <div className="left">
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'shift_name']}
                                      rules={[{ required: true, message: 'Please filled this field' }]}
                                    >
                                      <Input
                                        placeholder="Name"
                                        className="rs-input"
                                        prefix={<Clock size={18} variant="Bulk" />}
                                        disabled
                                      />
                                    </Form.Item>
                                  </div>
                                  <div className="center time-ranger">
                                    {/* <Form.Item
                                      {...restField}
                                      name={[name, 'time']}
                                      rules={[{ required: true, message: 'Please select time' }]}
                                    >
                                      <RangePicker
                                        // minuteStep={10}
                                        format="HH:mm"
                                        placeholder={['From', 'To']}
                                        className="t-range-picker-ctrl"
                                        suffixIcon={<WristClock size={20} variant="Bulk" />}
                                        disabled={settingRole && settingRole.is_edit === 0}
                                        needConfirm={false}
                                      />
                                    </Form.Item> */}
                                    <WristClock size={20} variant="Bulk" />
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'from_time']}
                                      rules={[{ required: true, message: 'Please select time' }]}
                                    >
                                      <TimePicker
                                        format="HH:mm"
                                        placeholder='From'
                                        suffixIcon={null}
                                        showNow={false}
                                        needConfirm={false}
                                        inputReadOnly
                                        disabledTime={() => disabledFromTime(item.id, times[item.id][index - 1]?.to)}
                                        className='rs-date-picker'
                                      />
                                    </Form.Item>
                                    <div className="horizontal-divider"></div>
                                    <Form.Item
                                      {...restField}
                                      name={[name, 'to_time']}
                                      rules={[{ required: true, message: 'Please select time' }]}
                                    >
                                      <TimePicker
                                        format="HH:mm"
                                        placeholder='To'
                                        suffixIcon={null}
                                        showNow={false}
                                        needConfirm={false}
                                        inputReadOnly
                                        disabled={!times[item.id][index]?.from}
                                        disabledTime={() => disabledToTime(item.id, times[item.id][index]?.from, times[item.id][0]?.from)}
                                        className='rs-date-picker'
                                      />
                                    </Form.Item>
                                  </div>
                                  <div className={`right ${index !== 0 ? 'cl-FD6A6A' : ''}`}>
                                    <Button
                                      disabled={settingRole && settingRole.is_delete === 0}
                                      icon={<Magicpen size="20" variant="Bulk" color={index !== 0 ? '#FD6A6A' : '#999999'} />}
                                      onClick={() => {
                                        if (index !== 0) {
                                          remove(name)
                                          setNameDf(item.name)
                                        }
                                      }}
                                    />
                                  </div>
                                </div>

                              ))}
                              { hasMonItem() === true && item.name === 'Mon' &&
                                <div
                                  className={`SelectedDay-note ${
                                    settingRole && settingRole.is_edit === 0 ? 'disabled' : '' }`}
                                >
                                  <span
                                    className='cl-primary'
                                    style={{ pointerEvents: settingRole && settingRole.is_edit === 0 ? 'none' : 'initial'}}
                                    onClick={changeAll}
                                  >Apply shift config</span>
                                  <span>to all working day</span>
                                </div>
                              }
                              <Form.Item>
                                <Button
                                  disabled={(settingRole && settingRole.is_create === 0) || disabledButton[item.id]}
                                  className='btn-add-new'
                                  onClick={() => add({shift_name: getName(item.name), time: ''})}
                                >
                                  <div className="icon-svg"><AddSquare size="20" color="#f9d361" variant="Bulk" /></div>
                                  <div className="text">Add new</div>
                                </Button>
                              </Form.Item>
                            </>
                          )}
                        </Form.List>
                      </div>
                    </div>
                  }
                </>
              )
            })
          }
        </Form>
      </div>
    </div>
  )
}

export default SelectedDay
