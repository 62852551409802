import React from 'react'
import { Breadcrumb, Button } from 'antd'
import { useNavigate } from 'react-router-dom'
import './BreadcrumbBlock.scss'

interface Props {
  items: Array<any>;
  title: string;
  backLink?: string;
  description?: string;
}

const ChevronRight = <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M7.29279 14.7069C7.10532 14.5194 7 14.2651 7 13.9999C7 13.7348 7.10532 13.4804 7.29279 13.2929L10.5858 9.99992L7.29279 6.70692C7.11063 6.51832 7.00983 6.26571 7.01211 6.00352C7.01439 5.74132 7.11956 5.49051 7.30497 5.3051C7.49038 5.11969 7.74119 5.01452 8.00339 5.01224C8.26558 5.00997 8.51818 5.11076 8.70679 5.29292L12.7068 9.29292C12.8943 9.48045 12.9996 9.73475 12.9996 9.99992C12.9996 10.2651 12.8943 10.5194 12.7068 10.7069L8.70679 14.7069C8.51926 14.8944 8.26495 14.9997 7.99979 14.9997C7.73462 14.9997 7.48031 14.8944 7.29279 14.7069Z" fill="currentColor"/> </svg>

const BreadcrumbBlock: React.FC<Props> = ({items, title, backLink, description}) => {
  const navigator = useNavigate()

  return (
    <div className="app-breadcrumb-block">
      <Breadcrumb
        separator={ChevronRight}
        items={items}
      />

      <div className="app-breadcrumb-title">
        {
          backLink &&
            // && <Link to={ backLink }><ArrowLeftOutlined style={{ fontSize: 18 }}/></Link>
            <Button
              className='rs-button back'
              onClick={() => navigator(backLink)}
            >Back</Button>
        }
        <div className="title">{ title }</div>
        {
          description
            && <div className="description">{ description }</div>
        }
      </div>
    </div>
  );
}

export default BreadcrumbBlock;
