import React, { useEffect, useState } from 'react'
import './UploadImage.scss'
import { Trash } from 'iconsax-react'
import {IMAGE_URL, IMAGE_URL2} from '../../env'

interface Props {
  imgUrl?: string,
  onChange?: (file: any) => void,
}

const UploadImage: React.FC<Props> = (props) => {
  const { imgUrl, onChange } = props

  // const [selectedFile, setSelectedFile] = useState<File | null>(null)
  const [previewUrl, setPreviewUrl] = useState<string | null>(null)

  useEffect(() => {
    if (imgUrl) {
      if (imgUrl.includes('/stores/')) {
        setPreviewUrl(`${IMAGE_URL2}${imgUrl}`)
      } else {
        setPreviewUrl(`${IMAGE_URL}${imgUrl}`)
      }
    } else {
      setPreviewUrl(null)
    }
  }, [imgUrl])

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0]
    if (file) {
      // setSelectedFile(file)
      setPreviewUrl(URL.createObjectURL(file))

      if (onChange) {
        const base64Image = await convertToBase64(file)
        onChange(base64Image)
      }
    }
  }

  const handleRemoveImage = () => {
    // setSelectedFile(null)
    setPreviewUrl(null)
  }

  const convertToBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        const base64String = reader.result as string
        resolve(base64String)
      }
      reader.onerror = (error) => {
        reject(error)
      }
    })
  }

  return (
    <div className={`upload-image ${!previewUrl ? 'empty' : ''}`}>
      <input type="file" onChange={handleFileChange} />
      {
        previewUrl
          ? (
            <div className="upload-image__preview">
              <img src={previewUrl} alt="Preview" />
              <Trash
                variant='Bulk'
                size={24}
                className="upload-image__remove"
                onClick={handleRemoveImage}
              />
            </div>
          )
          : <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M11.6665 26.6665C10.0249 26.6684 8.4403 26.0644 7.21633 24.9704C5.99235 23.8764 5.21507 22.3692 5.0334 20.7377C4.85172 19.1061 5.27843 17.4649 6.23177 16.1284C7.18511 14.7919 8.59804 13.8542 10.1999 13.4949C9.73641 11.3334 10.1506 9.07627 11.3513 7.22013C12.552 5.36399 14.4409 4.06085 16.6024 3.59738C18.7639 3.13392 21.021 3.54809 22.8771 4.74879C24.7333 5.9495 26.0364 7.83837 26.4999 9.99988H26.6665C28.7331 9.99781 30.7268 10.7637 32.2604 12.1488C33.7941 13.534 34.7584 15.4396 34.9661 17.4957C35.1738 19.5518 34.61 21.6118 33.3844 23.2757C32.1587 24.9395 30.3585 26.0886 28.3332 26.4999M24.9999 21.6665L19.9999 16.6665M19.9999 16.6665L14.9999 21.6665M19.9999 16.6665V36.6665" stroke="#9CA3AF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path> </svg>
      }
    </div>
  )
}

export default UploadImage
