import React, { useEffect, useRef, useState } from 'react';
import { Button, Flex, Form, FormInstance, FormProps, Input, Modal, notification } from 'antd';
import { Danger, TagCross } from 'iconsax-react';
import { mixPostV1 } from '../../services/mixin';
import { CheckCircleFilled } from '@ant-design/icons';
import Tripe from './Tripe/app'

const { TextArea } = Input

const closeIcon = <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg"> <path fillRule="evenodd" clipRule="evenodd" d="M3.86312 3.86363C4.03189 3.6949 4.26077 3.60012 4.49942 3.60012C4.73806 3.60012 4.96694 3.6949 5.13572 3.86363L8.99942 7.72733L12.8631 3.86363C12.9461 3.77767 13.0455 3.7091 13.1553 3.66194C13.2651 3.61477 13.3832 3.58994 13.5027 3.5889C13.6222 3.58786 13.7407 3.61063 13.8513 3.65589C13.9619 3.70114 14.0624 3.76797 14.1469 3.85247C14.2314 3.93697 14.2982 4.03746 14.3435 4.14807C14.3887 4.25867 14.4115 4.37719 14.4104 4.49669C14.4094 4.61619 14.3846 4.73429 14.3374 4.84409C14.2902 4.95389 14.2217 5.0532 14.1357 5.13623L10.272 8.99993L14.1357 12.8636C14.2997 13.0334 14.3904 13.2607 14.3883 13.4967C14.3863 13.7327 14.2916 13.9584 14.1248 14.1253C13.9579 14.2921 13.7322 14.3868 13.4962 14.3888C13.2602 14.3909 13.0329 14.3002 12.8631 14.1362L8.99942 10.2725L5.13572 14.1362C4.96598 14.3002 4.73863 14.3909 4.50266 14.3888C4.26668 14.3868 4.04095 14.2921 3.87408 14.1253C3.70721 13.9584 3.61256 13.7327 3.61051 13.4967C3.60846 13.2607 3.69918 13.0334 3.86312 12.8636L7.72682 8.99993L3.86312 5.13623C3.69439 4.96745 3.59961 4.73857 3.59961 4.49993C3.59961 4.26128 3.69439 4.0324 3.86312 3.86363Z" fill="#6B7280" /> </svg>

interface Props {
  isShow?: boolean
  storeId: any
  onModalClose?: () => void
  onModalSubmit?: () => void
}

const PaymentModal: React.FC<Props> = (props) => {
  const {
    isShow = false,
    onModalClose,
    onModalSubmit,
    storeId
  } = props

  const [open, setOpen] = useState(isShow)
  const [confirmLoading, setConfirmLoading] = useState(false);

  useEffect(() => {
    setOpen(isShow)
  }, [isShow])

  const onClose = () => {
    setOpen(false);
    if (onModalClose) {
      onModalClose()
    }
    if (formRef.current) {
      formRef.current.resetFields()
    }
  };

  // FORM SUBMIT
  const formRef = useRef<FormInstance>(null)
  const onSubmitForm = () => {
    if (formRef.current) {
      formRef.current.submit()
    }
  }
  const onSubmit = async (values: any) => {
    if (onModalSubmit) {
      setConfirmLoading(true)

      // if (apiUrl && param) {
      //   const res = await mixPostV1(apiUrl, param)
      //   if (res.status === 1) {
      //     setConfirmLoading(false)
      //     onModalSubmit()
      //     openNotification('success', message)
      //   } else {
      //     setConfirmLoading(false)
      //     openNotification('error', res.message)
      //   }
      // } else {
      //   setTimeout(() => {
      //     setOpen(false);
      //     setConfirmLoading(false);
      //     onModalSubmit()
      //     openNotification('success', message)
      //   }, 1000);
      // }
      setTimeout(() => {
        setOpen(false);
        setConfirmLoading(false);
        onModalSubmit()
        if (formRef.current) {
          formRef.current.resetFields()
        }
        openNotification('success', 'Successful!')
      }, 1000);
    } else {
      setConfirmLoading(false)
      onClose()
      openNotification('error', 'Something went wrong')
    }
  };
  const handleOk = async (values: any) => {
    setConfirmLoading(true)
    const el = document.getElementById("tripebtnaddv2")
    if (el) {
      el.click()
    }
  }
  const onFinish: FormProps["onFinish"] = (values) => {
    handleOk(values)
  };

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'error' = 'success', message = 'Card updated!') => {
    api[type]({
      message,
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  const cardChange = (e: any) => {
    if (e.id) {
      addCard(e)
    } else {
      setConfirmLoading(false)
    }
  }

  let waitx = false
  const addCard = async (e: any) => {
    if (waitx === true) {
      return
    }
    waitx = true
    const params = {
      store_id: storeId,
      tokenpayment: e.id
    } as any
    const res = await mixPostV1('/api/webAdmin/paymentPackedStore.php', params)
    if (res.status === 1) {
      waitx = false
      setConfirmLoading(false)
      openNotification('success')
      if (onModalSubmit) {
        onModalSubmit()
      }
      if (formRef.current) {
        formRef.current.resetFields()
      }
    } else {
      waitx = false
      setConfirmLoading(false)
      openNotification('error', res.message)
    }
  }

  return (
    <>
      {contextHolder}

      <Modal
        centered
        open={open}
        closeIcon={closeIcon}
        footer={null}
        confirmLoading={confirmLoading}
        className='rs-modal w-360 modal-payment'
        rootClassName='rs-modal-root'
        onOk={onSubmit}
        onCancel={onClose}
      >
        <Flex vertical gap={16}>
          <div className="title-24 text-center text-light">Payment</div>
          <Tripe onChange={(e: any) => cardChange(e)} />
          <Form
            ref={formRef}
            autoComplete="off"
            className='rs-form'
            requiredMark={false}
            style={{ gap: 16 }}
            onFinish={onFinish}
          >

            {/* <Form.Item
              name="card-number"
              label="Card number"
              rules={[{ required: true, message: 'This field must be filled!' }]}
            >
              <Input
                placeholder="XXXX XXXX XXXX XXXX"
                className="rs-input"
                type="number"
              />
            </Form.Item>

            <Form.Item
              name="mm-yy"
              label="MM/YY"
              rules={[{ required: true, message: 'This field must be filled!' }]}
            >
              <Input
                placeholder="MM/YY"
                className="rs-input"
              />
            </Form.Item>

            <Form.Item
              name="cvc-cvv"
              label="CVC/CVV"
              rules={[{ required: true, message: 'This field must be filled!' }]}
            >
              <Input
                placeholder="CVC/CVV"
                className="rs-input"
                type="number"
              />
            </Form.Item> */}

            <Form.Item
              label=""
              style={{ paddingBlockStart: 10 }}
            >
              <Flex align="center" gap={16}>
                <Button
                  block
                  className="rs-button default-outlined"
                  onClick={onClose}
                >Cancel</Button>
                <Button
                  loading={confirmLoading}
                  block
                  type="primary"
                  className="rs-button"
                  onClick={onSubmitForm}
                >Payment</Button>
              </Flex>
            </Form.Item>
          </Form>
        </Flex>
      </Modal>
    </>
  )
}

export default PaymentModal
