import React from 'react'
import './Player.scss'
import { Button } from 'antd'
import { Magicpen } from 'iconsax-react'

interface Props {
  id?: string,
  avatar?: string,
  name?: string,
  count?: number,
  disabled?: boolean,
  onDelete?: () => void
}

const GamePlayer: React.FC<Props> = (props) => {
  const { id, avatar, name, count, disabled = false, onDelete } = props

  const deleteItem = () => {
    if (onDelete) {
      onDelete()
    }
  }

  return (
    <div className={`game-player ${disabled ? 'disabled' : ''}`}>
      <img src={avatar} alt={name} className="avatar" />
      <div className="content">
        <div className="text-light">
          { name }&nbsp;
          {
            count && <span>[{count}]</span>
          }
        </div>
        <div className="text-idle">{ id }</div>
      </div>
      {
        onDelete &&
          <Button
            icon={<Magicpen size={20} variant="Bulk" />}
            className='rs-button primary-outline'
            onClick={() => deleteItem()}
          />
      }
    </div>
  )
}

export default GamePlayer
