import { Button, TimePicker, Form, Input, FormInstance, notification } from 'antd';
import { AddSquare, Clock, Magicpen, WristClock } from 'iconsax-react';
import { useEffect, useState, useRef } from 'react';
import { mixPostV1 } from '../../../services/mixin';
// import dayjs from 'dayjs'

// const { RangePicker } = TimePicker;
interface Props {
  dataAlwaysOpen?: any
  typeWork?: any
  isSubmit?: any
}
type shiftType = {
  shift_name?: string,
  time?: any
  from_time?: any
  to_time?: any
}
type FieldType = {
  shift?: shiftType[]
}

// CHECK ROLE = 'USER'
const user = JSON.parse(localStorage.getItem('user') || '{}')
const permission = user.permission
const settingRole = permission ? permission.find((item: any) => item.function_id === 7) : null

const AlwaysOpen: React.FC<Props> = (props) => {
  const { dataAlwaysOpen, typeWork, isSubmit } = props
  const [disabledButton, setDisabledButton] = useState(false)
  // const [dataAlwaysOpen, setStaffs] = useState<[{
  //   shift_name: string,
  //   from_time: string,
  //   to_time: string,
  // }]>([{ shift_name: '', from_time: '', to_time: '' }] as any)

  useEffect(() => {
    const initTimes = dataAlwaysOpen.map((item: any) => ({
      from: item.from_time.format('HH:mm'),
      to: item.to_time.format('HH:mm')
    }))
    setTimes(initTimes)
  }, [dataAlwaysOpen])

  useEffect(() => {
    if (isSubmit && isSubmit > 0) {
      onSubmitForm()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmit])

  const formRef = useRef<FormInstance<FieldType>>(null)
  const onSubmitForm = () => {
    if (formRef && formRef.current && Number(typeWork) === 0) {
      formRef?.current?.submit()
    }
  }

  const onFinish = async (values: any) => {
    const temp = [] as any
    values.shifts.map((item: any) => temp.push({
        shift_name: Number(item.shift_name.split('Shift ')[1]),
        from_time: item.from_time.format('HH:mm'),
        to_time: item.to_time.format('HH:mm')
      })
    )

    const res = await mixPostV1('/api/webAdmin/updateShiftByAlwayOpen.php', {
      array_shift: JSON.stringify(temp)
    })
    if (res.status === 1) {
      openNotification('success', res.message)
    } else {
      openNotification('error', res.message)
    }
  }

  const [times, setTimes] = useState([] as any)
  const onValuesChange = (changedValues: any, allValues: any) => {
    const timeArr: any = []
    // eslint-disable-next-line array-callback-return
    allValues.shifts.map((item: any, index: number) => {
      const {from_time, to_time} = item
      if (!from_time && !to_time) return false

      timeArr.push({
        from: from_time ? from_time?.format('HH:mm') : '',
        to: to_time ? to_time?.format('HH:mm') : ''
      })
    })
    setTimes(timeArr)
  }

  useEffect(() => {
    const firstShiftFromHour = Number(times[0]?.from?.split(':')[0])
    const lastShiftToTime = times[times.length - 1]?.to
    const lastShiftFromHour = Number(times[times.length - 1]?.from?.split(':')[0])
    const lastShiftToHour = lastShiftToTime ? Number(lastShiftToTime.split(':')[0]) : lastShiftFromHour

    const condition = firstShiftFromHour - lastShiftToHour === 1 || firstShiftFromHour === lastShiftToHour || firstShiftFromHour - lastShiftToHour === -23

    setDisabledButton(condition)
  }, [times])

  const disabledHours = (minHour: number, maxHour?: number) => {
    if (isNaN(minHour)) return []
    const conditionHour = maxHour ? maxHour : 0
    const hours: any = []

    const firstShiftFromHour = Number(times[0]?.from?.split(':')[0])
    const firstShiftFromMinute = Number(times[0]?.from?.split(':')[1])
    const lastShiftToTime = times[times.length - 1]?.to
    const lastShiftFromHour = Number(times[times.length - 1]?.from?.split(':')[0])
    const lastShiftToHour = lastShiftToTime ? Number(lastShiftToTime.split(':')[0]) : lastShiftFromHour
    const condition = lastShiftToHour < firstShiftFromHour
    const limitHour = firstShiftFromMinute === 0 ? firstShiftFromHour : firstShiftFromHour + 1

    if (condition) {
      for (let i = lastShiftToHour - 1; i >= 0; i--) {
        hours.push(i)
      }
      for (let i = limitHour; i < 24; i++) {
        hours.push(i)
      }
    } else {
      for (let i = minHour - 1; i >= conditionHour; i--) {
        hours.push(i)
      }
    }
    return hours
  }

  const disabledMinutes = (selectedHour: number, minHour: any, minMinute: any, isToTime?: boolean) => {
    const minutes: any = []
    const firstShiftHour = Number(times[0]?.from?.split(':')[0])
    const firstShiftMinute = Number(times[0]?.from?.split(':')[1])

    if (selectedHour === minHour) {
      for (let i = minMinute; i >= 0; i--) {
        minutes.push(i)
      }
    }
    if (selectedHour === firstShiftHour && isToTime) {
      for (let i = firstShiftMinute; i < 60; i++) {
        minutes.push(i)
      }
    }
    return minutes
  }

  const disabledFromTime = (minTime: string) => {
    const minHour = Number(minTime?.split(':')[0])
    const minMinute = Number(minTime?.split(':')[1])
    const limitMinHour = minMinute === 59 ? minHour + 1 : minHour

    return {
      disabledHours: () => disabledHours(limitMinHour),
      disabledMinutes: (selectedHour: any) => disabledMinutes(selectedHour, minHour,minMinute)
    }
  }

  const disabledToTime = (minTime: string, maxTime?: string) => {
    const minHour = Number(minTime?.split(':')[0])
    const minMinute = Number(minTime?.split(':')[1])
    const maxHour = Number(maxTime?.split(':')[0])
    const maxMinute = Number(maxTime?.split(':')[1])
    const limitMinHour = minMinute === 59 ? minHour + 1 : minHour
    const limitMaxHour = maxMinute > 0 ? maxHour + 1 : maxHour

    return {
      disabledHours: () => disabledHours(limitMinHour, limitMaxHour),
      disabledMinutes: (selectedHour: any) => disabledMinutes(selectedHour, minHour, minMinute, true)
    }
  }

  const getName = () => {
    let res = 'Shift 1'
    try {
      if (formRef && formRef.current) {
        const temp = formRef?.current?.getFieldsValue() as any
        res = `Shift ${temp.shifts.length + 1}`
      }
    } catch (error) {
      res = 'Shift 1'
    }
    return res
  }

  const setNameDf = () => {
    const data = formRef?.current?.getFieldsValue() as any
    if (data && data.shifts) {
      const temp = [] as any
      data.shifts.map((val: any, index: number) => {
        temp.push({
          ...val,
          shift_name: `Shift ${index + 1}`,
        })
        return true
      })
      data.shifts = temp
      formRef?.current?.setFieldsValue(data)
    }
  }

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification();
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = '' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    });
  };

  return (
    <div className="always-open">
      { contextHolder }
      <div className="title-top t-fz-12 fw-600">Shift</div>
      <Form
        ref={formRef}
        name="shift-always-open-form"
        onFinish={onFinish}
        autoComplete="off"
        onValuesChange={onValuesChange}
      >
        <Form.List
          name="shifts"
          initialValue={dataAlwaysOpen || []}
        >
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index: number) => (
                // <Form.Item
                //   {...restField}
                //   name={[name, 'first']}
                //   key={key}
                // >
                <div className="one-input-time" key={key}>
                  <div className="left">
                    <Form.Item
                      {...restField}
                      name={[name, 'shift_name']}
                      rules={[{ required: true, message: 'Please filled this field' }]}
                    >
                      <Input
                        disabled
                        placeholder="Name"
                        className="rs-input"
                        prefix={<Clock size={18} variant="Bulk" />}
                      />
                    </Form.Item>
                  </div>
                  <div className="center time-ranger">
                    {/* <Form.Item
                      {...restField}
                      name={[name, 'time']}
                      rules={[{ required: true, message: 'Please select time' }]}
                    >
                      <RangePicker
                        // minuteStep={10}
                        format="HH:mm"
                        placeholder={['From', 'To']}
                        className="t-range-picker-ctrl"
                        disabled={settingRole && settingRole.is_edit === 0}
                        suffixIcon={<WristClock size={20} variant="Bulk" />}
                        needConfirm={false}
                      />
                    </Form.Item> */}
                    <WristClock size={20} variant="Bulk" />
                    <Form.Item
                      {...restField}
                      name={[name, 'from_time']}
                      rules={[{ required: true, message: 'Please select time' }]}
                    >
                      <TimePicker
                        format="HH:mm"
                        placeholder='From'
                        suffixIcon={null}
                        showNow={false}
                        needConfirm={false}
                        inputReadOnly
                        disabledTime={() => disabledFromTime(times[index - 1]?.to)}
                        className='rs-date-picker'
                      />
                    </Form.Item>
                    <div className="horizontal-divider"></div>
                    <Form.Item
                      {...restField}
                      name={[name, 'to_time']}
                      rules={[{ required: true, message: 'Please select time' }]}
                    >
                      <TimePicker
                        format="HH:mm"
                        placeholder='To'
                        suffixIcon={null}
                        showNow={false}
                        needConfirm={false}
                        inputReadOnly
                        disabled={!times[index]?.from}
                        disabledTime={() => disabledToTime(times[index]?.from, times[0]?.from)}
                        className='rs-date-picker'
                      />
                    </Form.Item>
                  </div>
                  {/* <div
                    className={`right ${index !== 0 ? 'cl-FD6A6A' : ''}`}

                    onClick={() => {
                      if (index !== 0) {
                        remove(name)
                        setNameDf()
                      }
                    }}
                  ><Magicpen size="20" variant="Bulk" /></div> */}
                  <div className={`right ${index !== 0 ? 'cl-FD6A6A' : ''}`}>
                    <Button
                      disabled={settingRole && settingRole.is_delete === 0}
                      icon={<Magicpen size="20" variant="Bulk" color={index !== 0 ? '#FD6A6A' : '#999999'} />}
                      onClick={() => {
                        if (index !== 0) {
                          remove(name)
                          setNameDf()
                        }
                      }}
                    />
                  </div>
                </div>
                // </Form.Item>
              ))}
              <Form.Item>
                <Button
                  disabled={(settingRole && settingRole.is_create === 0) || disabledButton}
                  className='btn-add-new'
                  onClick={() => add({shift_name: getName(), from_time: '', to_time: ''})}
                >
                  <div className="icon-svg"><AddSquare size="20" color="#f9d361" variant="Bulk" /></div>
                  <div className="text">Add new</div>
                </Button>
              </Form.Item>
            </>
          )}
        </Form.List>
        {/* <Form.Item>
          <Button type="primary" htmlType="submit">
            Submit
          </Button>
        </Form.Item> */}
      </Form>
    </div>
  )
}

export default AlwaysOpen
