import React from 'react';
import ReactApexChart from 'react-apexcharts';

interface ApexChartProps { }

interface ApexChartState {
  series: {
    name: string;
    data: number[];
  }[];
  options: ApexCharts.ApexOptions;
}

class ApexChart extends React.Component<ApexChartProps, ApexChartState> {
  constructor(props: ApexChartProps) {
    super(props);

    this.state = {
      series: [
        {
          name: 'Sales',
          data: [
            0, 8, 16, 24, 32, 40, 48, 56, 64, 72
          ]
        }
      ],
      options: {
        chart: {
          height: 448,
          type: 'line' // Set type to 'line' here
        },
        forecastDataPoints: {
          count: 0
        },
        stroke: {
          width: 2,
          curve: 'smooth', // Change curve to 'smooth'
          lineCap: 'round'   // Add lineCap to 'round'
        },
        xaxis: {
          type: 'datetime',
          categories: [
            '01/02', '02/02', '03/02', '04/02', '05/02', '06/02', '07/02',
            '08/02', '09/02', '10/02'
          ],
          tickAmount: 10,
          labels: {
            formatter: function (value: string, timestamp: number, opts: any) {
              return opts.dateFormatter(new Date(timestamp), 'dd MMM');
            }
          }
        },
        title: {
          text: 'Match',
          align: 'left',
          style: {
            fontSize: '16px',
            color: '#999'
          }
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'dark',
            gradientToColors: ['#12B76A'],
            shadeIntensity: 1,
            type: 'horizontal',
            opacityFrom: 1,
            opacityTo: 1,
            stops: [0, 100, 100, 100]
          }
        },
        yaxis: {
          min: -10,
          max: 80 // Increase max value to accommodate increased data points
        },
      }
    };
  }

  render() {
    return (
      <>
        <div id="chart">
          <ReactApexChart options={this.state.options} series={this.state.series} height={448} />
        </div>
        <div id="html-dist"></div>
      </>
    );
  }
}

export default ApexChart;
