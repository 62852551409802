import React from 'react'
import './Button.scss'

interface Props {
  title?: string,
  disabled?: boolean,
  onClick?: () => void
}

const GameButton:React.FC<Props> = (props) => {
  const { title, disabled, onClick } = props

  return (
    <div className={`game-button ${disabled ? 'disabled' : ''}`} onClick={onClick}>
      <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M2.42527 4.11999C3.21268 4.11999 3.84835 4.75592 3.84835 5.53711C3.84835 6.31831 3.21268 6.95424 2.42527 6.95424C1.63787 6.95424 1.0022 6.31831 1.0022 5.53711C1.0022 4.75592 1.63787 4.11999 2.42527 4.11999Z" stroke="#F9D361"/> <path d="M7.43504 6.99577C8.22245 6.99577 8.85812 7.63169 8.85812 8.41289C8.85812 9.19409 8.22245 9.83002 7.43504 9.83002C6.64763 9.83002 6.01196 9.19409 6.01196 8.41289C6.01196 7.63169 6.64763 6.99577 7.43504 6.99577Z" stroke="#F9D361"/> <path d="M7.58567 0.588096C8.37308 0.588096 9.00875 1.22403 9.00875 2.00522C9.00875 2.78642 8.37308 3.42235 7.58567 3.42235C6.79827 3.42235 6.1626 2.78642 6.1626 2.00522C6.1626 1.22402 6.79827 0.588096 7.58567 0.588096Z" stroke="#F9D361"/> </svg>
      <span>{ title }</span>
      <svg width="10" height="11" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg"> <path d="M7.66249 6.2981C6.87509 6.2981 6.23942 5.66217 6.23942 4.88098C6.23942 4.09978 6.87509 3.46385 7.66249 3.46385C8.4499 3.46385 9.08557 4.09978 9.08557 4.88098C9.08557 5.66218 8.4499 6.2981 7.66249 6.2981Z" stroke="#F9D361"/> <path d="M2.65273 3.42217C1.86532 3.42217 1.22965 2.78624 1.22965 2.00505C1.22965 1.22385 1.86532 0.587921 2.65273 0.587921C3.44013 0.587921 4.07581 1.22385 4.07581 2.00505C4.07581 2.78624 3.44013 3.42217 2.65273 3.42217Z" stroke="#F9D361"/> <path d="M2.50222 9.82999C1.71481 9.82999 1.07914 9.19407 1.07914 8.41287C1.07914 7.63167 1.71481 6.99574 2.50222 6.99574C3.28962 6.99574 3.92529 7.63167 3.92529 8.41287C3.92529 9.19407 3.28962 9.82999 2.50222 9.82999Z" stroke="#F9D361"/> </svg>
    </div>
  )
}

export default GameButton
