import React from 'react';
// import { Helmet } from "react-helmet";
import './style.scss';
import { Link } from 'react-router-dom';

const BuyPackages: React.FC = () => {


  return (
    <div className='checkin-page checkin-packages'>
      <div className="bg-img">
        <img src="/images/checkin/img-sub-packages.png" alt="" />
      </div>
      <div className="content-block mw-1157">
        <div className="info-user">
          <div className="avata">
            <img src="/images/checkin/avata-member.png" alt="" />
          </div>
          <div className="id-user">Your App ID: 61261</div>
          <div className="title">Congrats <span>Hoai Thuong</span></div>
          <div className="sum cl-fff">Claim your free Voucher now!</div>
        </div>
        <div className="list-packages">
          <div className="one-item packages-d">
            <div className="img-bg">
              <img src="/images/checkin/bg-d.png" alt="" />
            </div>
            <div className="price">
              <i className='fz-80'>20$</i>
              <div className="text fz-24">Expire 03/20/24</div>
            </div>
            <Link to="/checkin/buy-success" className="btn h-68">Claim</Link>
          </div>

          <div className="one-item packages-b">
            <div className="img-bg">
              <img src="/images/checkin/bg-b.png" alt="" />
            </div>
            <div className="price">
              <i className='fz-80'>40$</i>
              <div className="text fz-24">Expire 03/20/24</div>
            </div>
            <Link to="/checkin/buy-success" className="btn h-68">Claim</Link>
          </div>

          <div className="one-item packages-v">
            <div className="img-bg">
              <img src="/images/checkin/bg-v.png" alt="" />
            </div>
            <div className="price">
              <i className='fz-80'>80$</i>
              <div className="text fz-24">Expire 03/20/24</div>
            </div>
            <Link to="/checkin/buy-success" className="btn h-68">Claim</Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BuyPackages;
