import React from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import './MatchChart.scss'

type dataType = {
  name: string,
  value: number,
}

interface Props {
  title?: string,
  data?: dataType[]
}

const MatchChart: React.FC<Props> = (props) => {
  const { title, data } = props
  // const chartData = data ? data : [{ name: '01/02', value: 12, }, { name: '02/02', value: 13 }, { name: '03/02', value: 14, }, { name: '04/02', value: 13.5, }, { name: '05/02', value: 16, }, { name: '06/02', value: 13, }, { name: '07/02', value: 30, }, { name: '08/02', value: 22, }, { name: '09/02', value: 23, }, { name: '10/02', value: 26, }]
  const chartData = data

  return (
    <div className="match-chart">
      <div className="title text-idle font-700">{ title }</div>

      <ResponsiveContainer height={392} width="100%">
        <LineChart
          data={chartData}
          margin={{
            top: 5,
            right: 30,
            left: 20,
            bottom: 5,
          }}
        >
          <defs>
            <linearGradient id="colorUv" x1="0%" y1="0" x2="100%" y2="0">
              <stop offset="0%" stopColor="rgba(18, 183, 106, 0.2)" />
              <stop offset="100%" stopColor="#12B76A" />
            </linearGradient>
          </defs>
          <CartesianGrid strokeDasharray="3 4" />
          <XAxis dataKey="name" />
          <YAxis width={30} />

          <Tooltip content={({active, payload, label}) =>
            {
              if (active && payload && payload.length)
                return (<span style={{ background: '#4B4F52', color: '#FFFFFF', borderRadius: '4px', padding: '8px', boxShadow: '0px 0px 12px 0px #00000033', lineHeight: 1 }}><span style={{ color: '#A5A5A5' }}>{label}</span>: {payload[0].value}</span>)

              return null
            }}
          />

          <Line
            type="monotone"
            dataKey="value"
            name=""
            strokeWidth={2}
            stroke="url(#colorUv)"
            dot={false}
          />
        </LineChart>
      </ResponsiveContainer>
    </div>
  )
}

export default MatchChart
