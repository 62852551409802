import React from 'react'
import { ArrowRightOutlined } from '@ant-design/icons'
import { Button } from 'antd'
import './ChooseStoreToGetDataRealTimeItem.scss'

interface Props {
  avatar?: string
  name?: string
  address?: string
  storeId?: number
  disabled?: boolean,
  onSelect?: (e: number) => void
  onShowModal?: (e: any) => void
}

const ChooseStoreToGetDataRealTimeItem:React.FC<Props> = (props) => {
  const { avatar = '/images/avatar/casino-avatar.png', name, address, storeId, disabled = false, onSelect, onShowModal } = props

  const selectStore = () => {
    if (onSelect) {
      onSelect(storeId ?? 0)
    }
  }

  const handleShowModal = () => {
    if (onShowModal) {
      onShowModal(storeId)
    }
  }

  return (
    <div className={`choose-store-item ${disabled ? 'disabled' : ''}`}>
      <div className="avatar">
        <img src={ avatar } alt={ name } />
        {/* <span className="avatar-title">My Store</span> */}
      </div>
      <div className="content">
        <div className="content-block-wrapper content-block-wrapper--with-button">
          <div className="content-block">
            <div className="title-12 font-bold text-idle label">Store name</div>
            <div className="title-15 font-medium text-light value">{ name }</div>
          </div>
          {
            disabled
              ?
                <Button
                  type="primary"
                  className='rs-button row-reverse'
                  icon={<ArrowRightOutlined />}
                  onClick={handleShowModal}
                >Payment</Button>
              :
                <Button
                  className='rs-button default-outlined row-reverse'
                  icon={<ArrowRightOutlined />}
                  onClick={selectStore}
                >Select</Button>
          }
        </div>

        <div className="content-block-wrapper">
          <div className="content-block">
            <div className="title-12 font-bold text-idle label">address</div>
            <div className="title-15 font-medium text-light value">{ address }</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChooseStoreToGetDataRealTimeItem
