import React, { useEffect, useState } from 'react';
// import { Helmet } from "react-helmet";
import './style.scss';
import { useNavigate } from 'react-router-dom';
import { Input, Form } from 'antd';

type FieldType = {
  otp1?: string;
  otp2?: string;
  otp3?: string;
  otp4?: string;
  otp5?: string;
  otp6?: string;
};

const CheckinOTP: React.FC = () => {
  // useEffect(() => {
  //   document.title = 'CMD';
  // }, []);

  const navigate = useNavigate()

  const [formData, setFormData] = useState<FieldType>({
    otp1: '',
    otp2: '',
    otp3: '',
    otp4: '',
    otp5: '',
    otp6: '',
  });

  useEffect(() => {
    const isFormFilled = Object.values(formData).every(value => value !== undefined && value !== '');
    if (isFormFilled) {
      // Nếu tất cả các trường đã điền đầy đủ dữ liệu, chuyển hướng về trang chủ
      navigate('/checkin-setup')
    }
  }, [formData, navigate]);

  const handleInputChange = (fieldName: string, value: any) => {
    // Cập nhật dữ liệu mới khi input thay đổi
    setFormData(prevData => ({
      ...prevData,
      [fieldName]: value
    }));
  };

  return (
    <div className='checkin-page checkin-otp'>
      <div className="bg-img">
        <img src="/images/checkin/bg-checkin-op1.jpg" alt="" />
      </div>
      <div className="content-block mw-708">
        <div className="title mb-24">Verify OTP</div>

        <div className="sum">
          Enter the OTP code sent to your phone number (555) *** 0100
        </div>

        <div className="ctrl-group-gender mb-24">
          <div className="label">Your OTP</div>
          <Form
            className="form-block"
            initialValues={{ remember: true }}
            autoComplete="off"
            layout="vertical"
          >
            <div className="t-col-6 mb-24">
              <Form.Item<FieldType>
                label=""
                name="otp1"
                rules={[{ required: true }]}
              >
                <div className="input-block">
                  <Input placeholder="" onChange={e => handleInputChange('otp1', e.target.value)} />
                </div>
              </Form.Item>

              <Form.Item<FieldType>
                label=""
                name="otp2"
                rules={[{ required: true }]}
              >
                <div className="input-block">
                  <Input placeholder="" onChange={e => handleInputChange('otp2', e.target.value)} />
                </div>
              </Form.Item>

              <Form.Item<FieldType>
                label=""
                name="otp3"
                rules={[{ required: true }]}
              >
                <div className="input-block">
                  <Input placeholder="" onChange={e => handleInputChange('otp3', e.target.value)} />
                </div>
              </Form.Item>

              <Form.Item<FieldType>
                label=""
                name="otp4"
                rules={[{ required: true }]}
              >
                <div className="input-block">
                  <Input placeholder="" onChange={e => handleInputChange('otp4', e.target.value)} />
                </div>
              </Form.Item>

              <Form.Item<FieldType>
                label=""
                name="otp5"
                rules={[{ required: true }]}
              >
                <div className="input-block">
                  <Input placeholder="" onChange={e => handleInputChange('otp5', e.target.value)} />
                </div>
              </Form.Item>

              <Form.Item<FieldType>
                label=""
                name="otp6"
                rules={[{ required: true }]}
              >
                <div className="input-block">
                  <Input placeholder="" onChange={e => handleInputChange('otp6', e.target.value)} />
                </div>
              </Form.Item>

            </div>
          </Form>
        </div>

        <div className="note-block">
          If you don't receive the OTP code, click <span>Resend OTP</span>
        </div>

      </div>
    </div>
  );
}

export default CheckinOTP;
