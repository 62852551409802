import React, { useState } from 'react'
import './style.scss'
import { useNavigate, useParams } from 'react-router-dom'
import { Input, Form, Button, FormInstance, notification, Modal, Flex } from 'antd'
import { Check, Eye, EyeSlash, LockCircle, Login, ShieldTick } from 'iconsax-react'
import { mixPutV1 } from '../../services/mixin'
import { CheckCircleFilled } from '@ant-design/icons'

const Home: React.FC = () => {
  const [form] = Form.useForm()
  const [passwordLengthAccept, setPasswordLengthAccept] = useState(false)
  const [passwordMatch, setPasswordMatch] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [responseMessage, setResponseMessage] = useState('')
  const pathParams = useParams()
  const navigator = useNavigate()

  // SUBMIT BUTTON
  interface SubmitButtonProps {
    form: FormInstance
  }
  const SubmitButton: React.FC<React.PropsWithChildren<SubmitButtonProps>> = ({ form }) => {
    const [submittable, setSubmittable] = React.useState<boolean>(false)

    // Watch all values
    const values = Form.useWatch([], form)

    React.useEffect(() => {
      form
        .validateFields({ validateOnly: true })
        .then(() => setSubmittable(true))
        .catch(() => setSubmittable(false))
    }, [form, values])

    return (
      <Button
        className={`btn t-btn-primary h-48 btn-new-pw `}
        type="primary"
        htmlType="submit"
        disabled={!submittable}
      >
        <div className="icon-svg">
          <Check variant="Bulk" />
        </div>
        <div className="text">
          Confirm new password
        </div>
      </Button>
    )
  }
  // END SUBMIT BUTTON

  // SUBMIT FORM
  const onFinish = async (values: any) => {
    const { code } = pathParams
    const params = {
      ownerCode: code,
      newPassword: values.password,
      confirmPassword: values.confirmPassword,
    }

    try {
      const res = await mixPutV1('/api/webAdmin/resetPassword.php', params)

      if (res.status === 1) {
        setResponseMessage(res.message)
        setShowModal(true)
        form.resetFields()
      } else {
        openNotification('error', res.message)
      }
    } catch (error) {
      openNotification('error', 'Oops, something went wrong!')
    }
  } // END SUBMIT FORM

  const onValuesChange = (changeValues: any, allValues: any) => {
    const { password, confirmPassword } = allValues

    if (password) {
      if (password.length >= 8 && password.length <= 15) {
        setPasswordLengthAccept(true)
      } else {
        setPasswordLengthAccept(false)
      }
    } else {
      setPasswordLengthAccept(false)
    }

    if (password === confirmPassword) {
      setPasswordMatch(true)
    } else {
      setPasswordMatch(false)
    }
  }

  // NOTIFICATION
  const [api, contextHolder] = notification.useNotification()
  const openNotification = (type: 'success' | 'info' | 'warning' | 'error' = 'success', message = '!' ) => {
    api[type]({
      message: message,
      placement: 'bottomRight',
      className: 'single-message'
    })
  }

  return (
    <>
      { contextHolder }

      <div className='login-page'>
        <div className="bg-img">
          <img src="/images/checkin/bg-checkin-op1.jpg" alt="" />
        </div>

        <div className="img-logo">
          <img src="/images/logo.png" alt="" />
        </div>

        <div className="content-block mw-464">
          <div className="title">Enter new password</div>
          <div className="sum t-fz-16">For your account security, please choose a new password that you haven’t used before</div>
          <Form
            className="form-login-block form-new-pw"
            form={form}
            autoComplete="off"
            layout="vertical"
            onFinish={onFinish}
            onValuesChange={onValuesChange}
          >
            <div className="input-icon-block mb-24">
              <Form.Item
                name="password"
                rules={[
                  { required: true, message: 'This field must be filled!' },
                  { min: 8 },
                  { max: 15 }
                ]}
              >
                <div className={`h-44 b-r`}>
                  <Input.Password
                    placeholder="Password"
                    prefix={<LockCircle variant="Bulk" />}
                    iconRender={(visible) => (visible ? <Eye variant="Bulk" /> : <EyeSlash variant="Bulk" />)}
                  />
                </div>
              </Form.Item>
            </div>

            <div className="input-icon-block mb-24">
              <Form.Item
                dependencies={['password']}
                name="confirmPassword"
                rules={[
                  {
                    required: true,
                    message: 'Please confirm your password!',
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (!value || getFieldValue('password') === value) {
                        return Promise.resolve()
                      }
                      return Promise.reject(new Error('The new password that you entered do not match!'))
                    },
                  }),
                ]}
              >
                <div className={`h-44 b-r`}>
                  <Input.Password
                    maxLength={15}
                    placeholder="Confirm password"
                    prefix={<LockCircle variant="Bulk" />}
                    iconRender={(visible) => (visible ? <Eye variant="Bulk" /> : <EyeSlash variant="Bulk" />)}
                    // onChange={handleConfirmPasswordChange}
                  />
                </div>
              </Form.Item>
            </div>

            <div className="list-error mb-48">
              <div className={`one-error t-fz-16 ${passwordLengthAccept ? 'active' : ''}`}>
                <div className="icon-svg">
                  <ShieldTick variant="Bulk" />
                </div>
                <div className="text">Password must be between 8 and 15 characters in length</div>
              </div>
              <div className={`one-error t-fz-16 ${passwordMatch ? 'active' : ''}`}>
                <div className="icon-svg">
                  <ShieldTick variant="Bulk" />
                </div>
                <div className="text">New password & confirmation password must match exactly.</div>
              </div>
            </div>

            <Form.Item shouldUpdate>
              <div className={`mb-0 btn-block active`}>
                <SubmitButton form={form} />
              </div>
            </Form.Item>
          </Form>
        </div>
      </div>

      <Modal
        centered
        open={showModal}
        maskClosable={false}
        // closeIcon={closeIcon}
        footer={null}
        className='rs-modal w-360 modal-delete'
        rootClassName='rs-modal-root'
        onCancel={() => setShowModal(false)}
      >
        <Flex vertical align='center' gap={16} className='text-center'>
          <CheckCircleFilled style={{ fontSize: 64, color: '#0CAF60' }}/>

          <Flex vertical gap={4}>
            <div className="title-20 text-light">Successfully!</div>
            <div>{ responseMessage }</div>
          </Flex>

          <Button
            block
            type='primary'
            icon={<Login variant='Bulk' />}
            className='rs-button row-reverse'
            onClick={() => navigator('/login')}
          >Go to login page</Button>
        </Flex>
      </Modal>
    </>
  )
}

export default Home
